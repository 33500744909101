export interface MerchantSettings {
    locationsCanOverride?: boolean;
    merchantType: MerchantTypes;
    merchantId?: string | null;
    storeAndForwardEnabled?: boolean | undefined;
    storeAndForwardTransactionThreshold?: number | undefined;
    storeAndForwardOutageThreshold?: number | undefined;
    tippingEnabled?: boolean | undefined;
    tippingSettings?: TippingSettings;

};

export interface TippingSettings {
    requestTipSelection?: boolean;
    tippingOther?: boolean | undefined;
    tippingType?: TippingTypeEnum;
    tipSelections?: TipSelection[] | undefined
};

export enum MerchantTypes {
    Manual = "NonIntegrated",
    Payload = "Payload", //Can be removed once Payload is removed from RicsApi
    RicsPay = "RicsPay"
};

export enum TippingTypeEnum{
    Percent = "Percent",
    Amount = "Amount"
};

export interface TippingType{
    id: string;
    label: string;
    eAdorn: string;
    sAdorn: string;
};

export const tippingType: Record<TippingTypeEnum, TippingType> = {
    [TippingTypeEnum.Percent]: { id: "Percent", label: "Percentage", sAdorn: "", eAdorn: "%" },
    [TippingTypeEnum.Amount]: { id: "Amount", label: "Amount", sAdorn: "$", eAdorn: "" }
};

export const MerchantTypesLabels = new Map<string, string>([
    [MerchantTypes.Manual, 'Non-Integrated'],
    [MerchantTypes.Payload, 'Payload'], //Can be removed once Payload is removed from RicsApi
    [MerchantTypes.RicsPay, 'RICS>Pay']
]);

export interface TipSelection {
    enabled: boolean;
    sortOrder: number;
    value?: number | string | null | undefined;
};

export const defaultTipSelections2 = [
    { enabled: true, sortOrder: 0, value: null },
    { enabled: true, sortOrder: 1, value: null },
    { enabled: true, sortOrder: 2, value: null  },
    { enabled: false, sortOrder: 3, value: null },
];